.Contentcss {
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 100%;
text-transform: capitalize;
color: #000000;
text-align: initial;
margin-bottom: 10px;
}