.Contentcss {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 100%;
text-transform: capitalize;
color: #000000;
text-align: initial;
padding: 5px;
}
.Input-css {
  width: '250px'
}
@media screen and (max-width: 800px){
  .Contentcss {
    font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 9px !important;
  line-height: 100%;
  text-transform: capitalize;
  color: #000000;
  text-align: initial;
  padding: 5px;
  }
}
@media screen and (max-width: 600px){
  .Contentcss {
    font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 8px !important;
  line-height: 100%;
  text-transform: capitalize;
  color: #000000;
  text-align: initial;
  padding: 5px;
  }
}