@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Text&display=swap');

/* 
*
*
Root Colours Configurations 
*
*
*/

:root {
  --primaryTheme: white;
  --appTheme: #101115;
  --appTheme-shade: #191a1f;
  --grey: #858588;
  --grey-light: #7f7f81;
  --dark: white;
  --light: #ffff;
  --tasksText: #332620;
  --profileMain: #f8fbff;
  --greenColor: rgba(101, 178, 110, 1);
}

::-webkit-scrollbar {
  display: none;
}

/* **Common App Configuration ***/

body {
  margin: 0;
  font-family: 'Red Hat Text', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #101115 !important;
  letter-spacing: 0.02em;
}

body::-webkit-scrollbar {
  display: none;
}

/* 
*
*
Basic App Components 
*
*
*/

.cursor-pointer {
  cursor: pointer;
}

.head-container {
  padding: 26px 40px 0;
  z-index: 999999999999999999;
  background-color: #101115;
}

.PageContainer {
  padding: 28px 40px;
  background-color: #101115;
}

@media only screen and (max-width: 600px) {
  .PageContainer {
    padding: 10px 20px;
    background-color: #101115;
  }
}

.divider {
  background-image: linear-gradient(
    to left,
    rgba(254, 254, 254, 0) 3%,
    white 100%
  );
  height: 2px;
  width: 100%;
  opacity: 0.4;
}

.scrollable-container {
  max-height: 300px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
}

.scrollable-container::-webkit-scrollbar {
  width: 6px;
}

.scrollable-container::-webkit-scrollbar-thumb {
  background-color: #afa9a9;
  border-radius: 10px;
}

.common-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/*
*
*
Application Typography  
*
*
*/

/* 
*
*
white Fonts 
*
*
*/

/* 12px white fonts */

.font-white-12-normal {
  font-size: 12px;
  color: var(--dark);
}

.font-white-12-bold {
  font-size: 12px;
  color: var(--dark);
  font-weight: bold;
}

/* 14px white fonts */

.font-white-14-normal {
  font-size: 14px;
  color: var(--dark);
}

.font-white-14-bold {
  font-size: 14px;
  color: var(--dark);
  font-weight: bold;
}

/* 16px white fonts */

.font-white-16-normal {
  font-size: 16px;
  color: var(--dark);
}

.font-white-16-bold {
  font-size: 16px;
  color: var(--dark);
  font-weight: bold;
}

/* 18px white fonts */

.font-white-18-bold {
  font-size: 18px;
  color: var(--dark);
  font-weight: bold;
}

/* 20px white fonts */

.font-white-20-bold {
  font-size: 20px;
  color: var(--dark);
  font-weight: bold;
}

/* 24px white fonts */

.font-white-24-bold {
  font-size: 24px;
  color: var(--dark);
  font-weight: bold;
}

/* 
*
*
Primary Fonts 
*
*
*/

/* 14px primary fonts */

.font-primary-14-bold {
  font-size: 14px;
  color: var(--primaryTheme);
  font-weight: bold;
}

/* 16px primary fonts */

.font-primary-16-bold {
  font-size: 16px;
  color: var(--primaryTheme);
  font-weight: bold;
}

/* 
*
*
Grey Fonts 
*
*
*/

/* 11px grey fonts */

.font-grey-11-normal {
  font-size: 11px;
  color: var(--grey);
}

/* 12px grey fonts */

.font-grey-12-normal {
  font-size: 12px;
  color: var(--grey);
}

.font-grey-12-bold {
  font-size: 12px;
  color: var(--grey);
  font-weight: bold;
}

/* 14px grey fonts */

.font-grey-14-bold {
  font-size: 14px;
  color: var(--grey);
  font-weight: bold;
}

/* 18px grey fonts */

.font-grey-18-normal {
  font-size: 18px;
  color: var(--grey);
  font-weight: 500;
}

/*
*
*
White Fonts 
*
*
*/

/* 16px white fonts */

.font-white-16-bold {
  font-size: 16px;
  color: var(--light);
  font-weight: bold;
}

.font-white-24-bold {
  font-size: 24px;
  color: var(--primaryTheme);
  font-weight: bold;
}

.font-white-12-bold {
  font-size: 12px;
  color: var(--appTheme);
  font-weight: normal;
}

.font-white1-16-bold {
  font-size: 16px;
  color: var(--appTheme);
  font-weight: 600;
}

.font-white-20-bold {
  font-size: 20px;
  color: var(--light);
  font-weight: 600;
}

/* 
*
*
Other Typographs
*
*
*/

.font-green-20-bold {
  font-size: 20px;
  color: var(--secondaryGreen);
  font-weight: bold;
}

.font-brown-20-bold {
  font-size: 20px;
  color: var(--BrownFont);
  font-weight: bold;
}

.font-green-12-bold {
  font-size: 12px;
  color: var(--secondaryGreen);
  font-weight: bold;
}

/* 
*
*
Status Tags 
*
*
*/

.review-status-button {
  border: 2px solid #482b04;
  background-color: #fdedd8;
  color: #480448;
  font-size: 12px;
}

.done-status-button {
  border: 2px solid #2d4804;
  background-color: #e8fdd8;
  color: #480448;
  font-size: 12px;
}

/* 
*
*
Application Buttons 
*
*
*/

.primary-button {
  background-color: var(--primaryTheme);
  color: #fff;
  border: none;
}

.secondary-button {
  background-color: #fff;
  color: var(--primaryTheme);
  border: 1px solid var(--primaryTheme);
}

.primary-contained-button {
  background-color: var(--primaryTheme);
  border: 0;
  border-radius: 4px;
  color: #ffff;
}

.transparent-button {
  background-color: var(--appTheme);
  color: #ffff;
}

.white-contained-button {
  background-color: white;
  color: black;
}

/* 
*
*
DashBoard Files 
*
*
*/

.border-bottom-startups {
  border-bottom: 2px solid var(--grey-light);
}

/* **Startup Files ***/

.input-field {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}

.input-field:focus {
  outline: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}

.double-border {
  margin: 0;
  padding-bottom: 7px;
  position: relative;
  border-bottom: 1px solid #000;
}

.double-border::before {
  position: absolute;
  background: red;
  height: 3px;
  content: '';
  width: 98px;
  bottom: -2px;
  left: 0;
}

.floating-button {
  position: fixed;
  bottom: 6%;
  right: 2.5%;
}

/* **Loaders ***/

.app-loader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
